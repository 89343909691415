import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Switch } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import style from './style';

export const WillCallConfirmationNotifications = () => {
  const [checked, setChecked] = useState(true);
  const [enable, setEnable] = useState('Enabled');

  const Styled = styled.div`
    ${style}
  `;

  useEffect(() => {
    if (checked) {
      setEnable('Enabled');
    } else {
      setEnable('Disabled');
    }
  }, [checked]);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Styled>
        <Paper elevation={3} className="notification-will-call-confirmation-notification-toggle">
          <Box className="notification-will-call-left-box">
            <Typography variant="h6">
              <FormattedMessage
                id={'willCallConfirmationNotification'}
                defaultMessage={'willCallConfirmationNotification'}
              />
            </Typography>
            <Typography>{enable}</Typography>
          </Box>
          <Box className="notification-will-call-right-box">
            <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
          </Box>
        </Paper>
      </Styled>
    </>
  );
};
